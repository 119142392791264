import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
window.jQuery = $;
window.$ = $;
require('jquery')
import "owl.carousel2"
import "select2"
import "moment"
import "eonasdan-bootstrap-datetimepicker"
import "ion-rangeslider"
import "selectric"
import "daterangepicker"
import owlCarousel from "owl.carousel2";
import "owl.carousel2/dist/assets/owl.carousel.css";
require("@nathanvda/cocoon")
import intlTelInput from 'intl-tel-input';
import "owl.carousel2/dist/assets/owl.carousel.css";
import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts;
require('easy-autocomplete')
import "datatables"
require("trix")
require("@rails/actiontext")
import 'bootstrap-tagsinput';
global.toastr = require("toastr")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function () {
  const allRanges = document.querySelectorAll(".range-wrap");
  allRanges.forEach(wrap => {
    const range = wrap.querySelector(".range")
    const bubble = wrap.querySelector(".bubble");
    if(range && bubble) {
      range.addEventListener("input", () => {
        setBubble(range, bubble);
      });
      setBubble(range, bubble);
    }
  });

  $('.orders-table').DataTable( {
    rowReorder: true,
    searching: false,
    paging: false,
    order: [[2, "desc"]],
    columnDefs: [
      { targets: 2, type: 'date-dd-mmm-yyyy' }
    ]
  });

  $('.dashboard-table').DataTable( {
    rowReorder: true,
    searching: false,
    paging: false,
    order: [[0, "desc"]],
    columnDefs: [
      { targets: 0, type: 'date-dd-mmm-yyyy' }
    ]
  });

  $('.history-table').DataTable( {
    rowReorder: true,
    searching: false,
    paging: false,
    order: [[0, "desc"]]
  });

  const messageForm = document.getElementById('message_form');
  const contentField = document.getElementById('message_content');
  const uploadField = document.getElementById('upload');
  const previewContainer = document.getElementById('file-preview-container');

  if (uploadField) {
    uploadField.addEventListener('change', () => {
      previewContainer.innerHTML = '';
      previewContainer.classList.remove('d-none');
      const file = uploadField.files[0];
      if (file) {
        const fileReader = new FileReader();
        
        fileReader.onload = function(event) {
          const fileType = file.type.split('/')[0];
          if (fileType === 'image') {
            const img = document.createElement('img');
            img.src = event.target.result;
            img.style.maxWidth = '100%';
            img.style.height = 'auto';
            img.style.objectFit = 'contain';
            previewContainer.appendChild(img);
          } else if (fileType === 'video') {
            const video = document.createElement('video');
            video.controls = true;
            video.style.maxWidth = '100%';
            video.style.height = 'auto';
            video.style.objectFit = 'contain';
            const source = document.createElement('source');
            source.src = event.target.result;
            source.type = file.type;
            video.appendChild(source);
            previewContainer.appendChild(video);
          } else {
            const fileLink = document.createElement('a');
            fileLink.href = event.target.result;
            fileLink.target = '_blank';
            fileLink.textContent = 'Preview ' + file.name;
            previewContainer.appendChild(fileLink);
          }
        };

        fileReader.readAsDataURL(file);
      }
    });
  }

  if (messageForm) {
    messageForm.onsubmit = (event) => {
      const messageContent = contentField.value.trim();
      const hasFile = uploadField.files.length > 0;
      if (messageContent === '' && !hasFile) {
        contentField.style.borderColor = 'red';
        alert('Please enter a message or attach a file before submitting.');
        event.preventDefault();
        return false;
      } else {
        contentField.style.borderColor = '';
      }
      return true;
    };
  }
  
  $(".demo_1").ionRangeSlider({
    type: "double",
    grid: true,
    min: 0,
    max: 1000,
    from: 0,
    to: 1000
  });

  $('.daterange').daterangepicker({
      autoUpdateInput: false,
      locale: {
          cancelLabel: 'Clear'
      }
  });

  $('.daterange').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
  });

  $('.daterange').on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
  });

  var options = {

    url: function(phrase) {
      return "/homes/suggest.json?query=" + phrase;
    },
    getValue: "name",
    list: {
      onChooseEvent: function() {
        var from = $('#from_city_id').val() || $('#leavingfrom').getSelectedItemData().id;
        var to = $('#to_city_id').val() || $('#goingto').getSelectedItemData().id;
        $('#from_city_id').val(from);
        $('#to_city_id').val(to);
      }
    }
  };

  $("#leavingfrom").easyAutocomplete(options);
  $("#goingto").easyAutocomplete(options);


  $('.btn-outline-expand').click(function () {
    $("i", this).toggleClass("fa-plus fa-minus");
  });
  $('.arriveDate').datetimepicker({
    format: 'YYYY-MM-DD',
    icons: {
      up: "fa fa-chevron-circle-up",
      down: "fa fa-chevron-circle-down",
      next: 'fa fa-chevron-circle-right',
      previous: 'fa fa-chevron-circle-left'
    }
  });
  $('.departDate').datetimepicker({
    format: 'YYYY-MM-DD',
    useCurrent: false,
    icons: {
      up: "fa fa-chevron-circle-up",
      down: "fa fa-chevron-circle-down",
      next: 'fa fa-chevron-circle-right',
      previous: 'fa fa-chevron-circle-left'
    }
  });

  $('.search-arrival-date').datetimepicker({
    format: 'YYYY-MM-DD',
    icons: {
      up: "fa fa-chevron-circle-up",
      down: "fa fa-chevron-circle-down",
      next: 'fa fa-chevron-circle-right',
      previous: 'fa fa-chevron-circle-left'
    }
  });
  $('.search-departure-date').datetimepicker({
    format: 'YYYY-MM-DD',
    useCurrent: false,
    icons: {
      up: "fa fa-chevron-circle-up",
      down: "fa fa-chevron-circle-down",
      next: 'fa fa-chevron-circle-right',
      previous: 'fa fa-chevron-circle-left'
    }
  });

  $(".arriveDate").on("dp.change", function (e) {
    $('.departDate').data("DateTimePicker").minDate(e.date);
  });
  $(".departDate").on("dp.change", function (e) {
    $('.arriveDate').data("DateTimePicker").maxDate(e.date);
  });
  $('.datepicker').datetimepicker({
    format: 'DD-MM-YY',
    icons: {
      up: "fa fa-chevron-circle-up",
      down: "fa fa-chevron-circle-down",
      next: 'fa fa-chevron-circle-right',
      previous: 'fa fa-chevron-circle-left'
    }
  });

  // bag form
  $('.bagDate').datetimepicker({
    format: 'YYYY-MM-DD',
    icons: {
      up: "fa fa-chevron-circle-up",
      down: "fa fa-chevron-circle-down",
      next: 'fa fa-chevron-circle-right',
      previous: 'fa fa-chevron-circle-left'
    }
  });
  $('.bagdepartDate').datetimepicker({
    format: 'YYYY-MM-DD',
    useCurrent: false,
    icons: {
      up: "fa fa-chevron-circle-up",
      down: "fa fa-chevron-circle-down",
      next: 'fa fa-chevron-circle-right',
      previous: 'fa fa-chevron-circle-left'
    }
  });
  $('.bagarriveDate').datetimepicker({
    format: 'YYYY-MM-DD',
    useCurrent: false,
    icons: {
      up: "fa fa-chevron-circle-up",
      down: "fa fa-chevron-circle-down",
      next: 'fa fa-chevron-circle-right',
      previous: 'fa fa-chevron-circle-left'
    }
  });

  $(".bagDate").on("dp.change", function (e) {
    $(this).data("DateTimePicker").minDate(e.date);
    $('.bagdepartDate').data("DateTimePicker").minDate(e.date);
    $('.bagarriveDate').data("DateTimePicker").minDate(e.date);
  });
  $(".bagdepartDate").on("dp.change", function (e) {
    $('.bagDate').data("DateTimePicker").maxDate(e.date);
    $('.bagarriveDate').data("DateTimePicker").minDate(e.date);
  });
  $(".bagarriveDate").on("dp.change", function (e) {
    $('.bagdepartDate').data("DateTimePicker").maxDate(e.date);
    $('.bagDate').data("DateTimePicker").maxDate(e.date);
  });

  $('header .navbar-nav a').on('click', function () {
    $('header .navbar-nav').find('li.active').removeClass('active');
    $(this).parent('li').addClass('active');
  });

  $('#inner-menu a').on('click', function () {
    $('.navbar-nav').find('li.active').removeClass('active');
    $(this).parent('li').addClass('active');
  });

  if ($("#telephoneCountry").length > 0) {
    const input = document.querySelector("#telephoneCountry");
    intlTelInput(input, {
      preferredCountries: ['us', 'gb', 'th']
    });
  }
  $('.custom-select-dropdown').selectric();

// owl carousel
  if ($(".package-slider").length) {
    $(".package-slider").owlCarousel({
      autoplay: true,
      autoplayTimeout: 3000,
      items: 2.5,
      animateOut: 'fadeOut',
      margin: 10,
      nav: true,
      responsive:{
        0:{
            items:1,
            dots: true,
        },
        600:{
            items:2,
            dots: true,
        },
        1000:{
            items:2.3,
            nav:true,
        }
    }
    });
    $(".owl-prev").html('<i class="fa fa-chevron-left"></i>');
    $(".owl-next").html('<i class="fa fa-chevron-right"></i>');
  }


  /* Second Slider JS Code*/
  var carousel = $(".client-slider");
  carousel.owlCarousel({
    autoplay: true,
    autoplayTimeout: 3000,
    loop: true,
    items: 5,
    dots: false,
    nav: true,
    center: true,
    responsive:{
      0:{
          items:1,
          nav:true
      },
      600:{
          items:1,
          nav:true
      },
      1000:{
          items:3,
          nav:true,
          loop:false
      }
    }
  });
  $(".owl-prev").html('<i class="fa fa-chevron-left"></i>');
  $(".owl-next").html('<i class="fa fa-chevron-right"></i>');
  var total = $('.client-slider .owl-stage .owl-item.active').length;
  $('.client-slider .owl-stage .owl-item').removeClass('firstActiveItem lastActiveItem');
  $('.client-slider .owl-stage .owl-item.active').each(function (index) {
    if (index === 1) {
      $(this).addClass('firstActiveItem');
    }
    if (index === total - 2 && total > 1) {

      $(this).addClass('lastActiveItem');
    }
  });
  carousel.on('translated.owl.carousel', function (event) {
    var total = $('.client-slider .owl-stage .owl-item.active').length;
    $('.client-slider .owl-stage .owl-item').removeClass('firstActiveItem lastActiveItem');

    $('.client-slider .owl-stage .owl-item.active').each(function (index) {
      if (index === 1) {
        // this is the first one
        $(this).addClass('firstActiveItem');
      }
      if (index === total - 2 && total > 1) {
        // this is the last one
        $(this).addClass('lastActiveItem');
      }
    });
  });

  function setCookie(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
  }

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  $(window).scroll(function () {
    if ($(this).scrollTop() > 80) {
      $('.navbar').addClass('darkheader');
    } else {
      $('.navbar').removeClass('darkheader');
    }
  });

  $(document).on('click', '.searchPanel.dropdown-menu', function (e) {
    e.stopPropagation();
  });

  $(document).on('click', '.searchPanelTo.dropdown-menu', function (e) {
    e.stopPropagation();
  });

  // chart
  if ($("#regions_div").length > 0) {
    google.charts.load('current', {
      'packages': ['geochart'],
      'mapsApiKey': 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY'
    });
    google.charts.setOnLoadCallback(drawRegionsMap);

    function drawRegionsMap() {
      $.ajax({
        url: 'high_voltage/pages/trip_counts_by_country',
        type: 'GET',
        dataType: 'json',
        success: function(data) {
          var tripData = [['Country', 'Number of Trips']];
          data.forEach(function(trip) {
            tripData.push([trip[0], trip[1]]);
          });
          var data = google.visualization.arrayToDataTable(tripData);
          var options = {
            colorAxis: {
              minValue: 0,
              maxValue: 1,
              colors: ['#CCCCCC', '#7598c0']
            },
            legend: 'none',
    
          };
    
          var chart = new google.visualization.GeoChart(document.getElementById('regions_div'));
          chart.draw(data, options);
        },
        error: function(xhr, status, error) {
          console.error(error);
        }
      });
      
    }
  }

  if ($(".selectFilter").length > 0) {
    $('.selectFilter').select2({
      placeholder: "Filter",
      allowClear: true
    });
  }
  if ($(".selectSort").length > 0) {
    $('.selectSort').select2({
      placeholder: "Filter",
      allowClear: true
    });
  }

  if ($(".cityFromFilter").length > 0) {
    $('.cityFromFilter').select2({
      placeholder: "Select Departure City",
      allowClear: true
    });
  }
  if ($(".cityToFilter").length > 0) {
    $('.cityToFilter').select2({
      placeholder: "Select Arrival City",
      allowClear: true
    });
  }

  // Apex chart
  if ($("#chart").length > 0) {
    var options = {
      chart: {
        type: 'bar',
        width: '100%',
        height: '265px'
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: '25%',
          dataLabels: false
        }
      },
      fill: {
        colors: ['#2E5DAA', '#F1A000']
      },
      series: [{
        name: 'sales',
        data: [200, 400, 500, 1000, 500, 510, 100, 200, 400, 500, 1000, 1200],
        labels: false
      }],
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      }
    }
    var chart = new ApexCharts(document.querySelector("#chart"), options);
    chart.render();
  }
  $('[data-toggle="tooltip"]').tooltip();
  // $('.minus').click(function () {
  //   var $input = $(this).parent().find('input');
  //   var count = parseInt($input.val()) - 1;
  //   count = count < 1 ? 1 : count;
  //   $input.val(count);
  //   $input.change();
  //   return false;
  // });
  // $('.plus').click(function () {
  //   var $input = $(this).parent().find('input');
  //   $input.val(parseInt($input.val()) + 1);
  //   $input.change();
  //   return false;
  // });
});

// $( document ).on('turbolinks:load', function() {
//   // setCookie("locale", gon.locale, 30);
//   // alert(gon.locale)
//   // gon.cookie_locale = getCookie("locale")
//   // gon.locale = getCookie("locale")
// });

// Trip discount, currency and currency conversion rate
$(document).on("change", "[name='trip[currency]']", function() {
  var conversionRate;
  if($("[name='trip[exchange_rate]']").val()) {
    conversionRate = $("[name='trip[exchange_rate]']").val();
  } else {
    conversionRate = 1
  }

  var currencyUnit;
  if($("[name='trip[currency]'] option:selected").val()) {
    currencyUnit = $("[name='trip[currency]'] option:selected").val()
  } else {
    currencyUnit = "USD"
  }
  $(".per-kg-usd").each(function() {
    var conversionInThb = $(this).val() * conversionRate
    $(this).parents(".justify-content-between").find(".per-kg-thb").val(conversionInThb.toFixed(2) + " " + currencyUnit)
  })
});
$(document).on("keyup", ".per-kg-usd", function(event) {
  var conversionRate;
  if($("[name='trip[exchange_rate]']").val()) {
    conversionRate = $("[name='trip[exchange_rate]']").val();
  } else {
    conversionRate = 1
  }
  var currencyUnit;
  if($("[name='trip[currency]'] option:selected").val()) {
    currencyUnit = $("[name='trip[currency]'] option:selected").val()
  } else {
    currencyUnit = "USD"
  }
  var conversionInThb = event.target.value * conversionRate
  $(event.target).parents(".justify-content-between").find(".per-kg-thb").val(conversionInThb.toFixed(2) + " " + currencyUnit)
});
$(document).on("keyup", "[name='trip[exchange_rate]']", function(event) {
  var conversionRate;
  if($("[name='trip[exchange_rate]']").val()) {
    conversionRate = $("[name='trip[exchange_rate]']").val();
  } else {
    conversionRate = 1
  }
  var currencyUnit;
  if($("[name='trip[currency]'] option:selected").val()) {
    currencyUnit = $("[name='trip[currency]'] option:selected").val()
  } else {
    currencyUnit = "USD"
  }

  $(".per-kg-usd").each(function() {
    var conversionInThb = $(this).val() * conversionRate
    $(this).parents(".justify-content-between").find(".per-kg-thb").val(conversionInThb.toFixed(2) + " " + currencyUnit)
  })
})

//  Plus minus changes

$(document).on('click', ".minus", function() {
  let itemPrice = $(this).data('item-price');
  let priceDiv = $(this).data("price-item");
  let itemDiv = $(this).data("item-div");
  $(this).parents(".input-group").find("input[type='number']").val(function(i, value) {
    let beforeValue = value;
   
    if(beforeValue>0 && beforeValue<1){
      var newVal = 0;
    }else{
      var newVal = --value;
    }
    var newPrice = itemPrice * newVal
    $(priceDiv).html(newPrice)
    if(itemDiv == "items"){
      let inputFieldVal = $('.total-item-weight').html();
      if (inputFieldVal != 0 && newVal >= 0 ){
        if(beforeValue>0 && beforeValue<1){
          $('.total-item-weight').html(inputFieldVal-beforeValue);
        }else{
          $('.total-item-weight').html(inputFieldVal-1);
        }
      } else if (inputFieldVal == 0) {
        $('.total-item-weight').html(0);
      }
    }
    if(newVal > 0) {
      return Math.abs(newVal)
    } else {
      return 0
    }
  });
});

$(document).on('click', ".plus", function() {
  let itemPrice = $(this).data('item-price');
  let priceDiv = $(this).data("price-item");
  let itemDiv = $(this).data("item-div");
  let totalWeight = +$('.total-item-weight').data("total-weight");
  toastr.remove();
  $(this).parents(".input-group").find("input[type='number']").val(function(i, value) {
    
    var newVal = ++value;
    var newPrice = itemPrice * newVal
    $(priceDiv).html(newPrice)
    let inputFieldVal = $('.total-item-weight').html();
    if((itemDiv == "items") && (inputFieldVal <= totalWeight)){
      ++inputFieldVal;
    }

    if (newVal > 0) {
      if ((itemDiv == "items") && (inputFieldVal > totalWeight)) {
        toastr.error(`Total Available weight is ${totalWeight}kg. You can't add more than this.`);
        setTimeout(function () {
          $('#total-weight-error').text("");
        }, 3000);
        return Math.abs(newVal-1);
      } else {
        $('.total-item-weight').html(inputFieldVal);
        $('#total-weight-error').text(""); 
        return Math.abs(newVal);
      }
    } else {
      return 0
    }
  });
});

window.setBubble = function(range, bubble) {
  const val = range.value;
  const min = range.min ? range.min : 0;
  const max = range.max ? range.max : 100;
  const newVal = Number(((val - min) * 100) / (max - min));
  bubble.innerHTML = val;
  // Sorta magic numbers based on size of the native UI thumb
  bubble.style.left = `calc(${newVal}% + (${6 - newVal * 0.15}px))`;
}

$(document).on('click', ".cancel_btn_flash", function() {
  $(".hide_flash_section").hide();
});

$(window).on('resize', function(){
  if (window.innerWidth < 769) {
    // location.reload();
    $('.per-kg-thb').addClass("text-center");
  }else{
    $('.per-kg-thb').removeClass("text-center");
  }
});

$.fn.dataTable.ext.type.order['date-dd-mmm-yyyy-pre'] = function(date) {
  var parts = date.split(' ');
  var day = parseInt(parts[0], 10);
  var month = {
      Jan: 0, Feb: 1, Mar: 2, Apr: 3,
      May: 4, Jun: 5, Jul: 6, Aug: 7,
      Sep: 8, Oct: 9, Nov: 10, Dec: 11
  }[parts[1]];
  var year = parseInt(parts[2], 10);
  
  return new Date(year, month, day).getTime();
};

$(document).on('click','.image-preview', function(){
  let modalId = $(this).data("modal-id");
  $("#"+modalId).modal("show");
})

$(document).on('select2:open', () => {
  $('.select2-search__field')[0].focus();
});

window.packageImages = function(input, event) {
  const fileLength = input.files.length;
  const maxFiles = 4;
  const parentDiv = $(input).closest('.file-image-field'); 

  const previewContainerWrapper = parentDiv.find(".product-photo");
  previewContainerWrapper.empty().removeClass("d-none");

  if (fileLength > maxFiles) {
   toastr.error(`You can upload a maximum of ${maxFiles} images only.`);
    parentDiv.find('.add-purchase').addClass('d-none');
    parentDiv.find('.book-item').addClass('d-none');
    parentDiv.find("#add-purchase-responsive").addClass("d-none");
  } else {
    parentDiv.find('.purchase-count-message').text('');
    parentDiv.find('.add-purchase').removeClass('d-none');
    parentDiv.find('.book-item').removeClass('d-none');
    parentDiv.find("#add-purchase-responsive").removeClass("d-none");
    $.each(input.files, function(index, file) {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = function(e) {
          const imgElement = $('<img>', {
            src: e.target.result,
            class: 'product-img-prev',
            width: '123px',
            style: 'margin-right: 5px;',
            'data-index': index
          });
          const removeIcon = $('<div>', {
            class: 'remove-icon d-flex justify-content-center align-items-center remove-image',
            text: '×',
            click: function() {
              removeEditImage(imgElement, removeIcon, input, index, parentDiv); 
            }
          });
          const previewItem = $('<div>', {
            class: 'position-relative',
            append: [imgElement, removeIcon]
          });
          previewContainerWrapper.append(previewItem);
        };
        reader.readAsDataURL(file);
        $('.package-image-btn').removeClass('d-none');
      } else {
        alert('Please upload only images.');
      }
    });
  }
}